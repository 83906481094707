import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGetCompanies } from "../../api/Company";
import { getCompanyUsersExtended, useToggleCompanyActive } from "../../api/CompanyApi";
import { Icon } from "../../assets/Icons/IconClassNames";
import ContentContainer from "../../components/ContainerComponents/ContentContainer";
import CustomDropdown, { DropdownToggleType } from "../../components/CustomDropdown/CustomDropdown";
import { CustomDropdownItem } from "../../components/CustomDropdown/CustomDropdownItem/CustomDropdownItem";
import PaginatedDataTableClientSide from "../../components/DataTableComponents/PaginatedDataTable";
import SimpleConfirmModal from "../../components/ModalComponents/SimpleConfirmModal";
import When from "../../components/When";
import { CompanyType } from "../../core/enums/enums";
import { ICompany, IHeading } from "../../core/interfaces/company";
import { useGetUser } from "../../hooks/useAuth";
import { useCNavigate } from "../../hooks/useCNavigate";
import { useAppDispatch } from "../../store/hooks";
import { changeCompany, changeCompanyUser } from "../../store/slices/authSlice";
import CompanyEditLogoModal from "./Components/CompanyEditLogoModal";
import EditCompanyModal from "./EditCompanyModal";


interface ICompanyTable {
    parentCompanyId?: string | null;
    searchable?: boolean;
    navigateOnName?: boolean;
    rowPointerOnHover?: boolean;
    onRowClick?: (row: ICompany) => void;
    showSettings?: boolean;
}

const CompanyTable = (
    {
        parentCompanyId,
        searchable,
        navigateOnName,
        rowPointerOnHover,
        onRowClick,
        showSettings,
    }: ICompanyTable) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const navigate = useCNavigate();
    const user = useGetUser();
    const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
    const [showEditLogoModal, setShowEditLogoModal] = useState(false);
    const [showInactiveCompanyModal, setShowInactiveCompanyModal] = useState(false);
    const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);

    const hide = () => {
        setShowEditCompanyModal(false)
    }

    const [toggleCompanyActive, isTogglingCompanyActive] = useToggleCompanyActive(selectedCompany?.id ?? "");

    const handleChangeCompany = async (company: ICompany) => {
        dispatch(changeCompany(company));

        const companyUsers = await getCompanyUsersExtended(company.id);
        const currentUser = companyUsers!.find(s => s.userId === user.userId);
        dispatch(changeCompanyUser(currentUser));

        navigate("/c/" + company.id);
        queryClient.invalidateQueries(["multiChoiceOptions"]);
    }

    const handleToggleCompanyActive = async () => {
        if (!selectedCompany) return;
        await toggleCompanyActive();
        setSelectedCompany(null);
        setShowInactiveCompanyModal(false);
    }

    const renderHeadings = (headings: IHeading[]) => {
        return (
            <div style={{ whiteSpace: 'pre-line' }}>
                {headings?.map((heading, index) => (
                    <span key={heading.id}>
                        {heading.name}
                        {index < headings.length - 1 ? ", \n" : ""}
                    </span>
                ))}
            </div>
        );
    }

    const renderStatus = (isActive: boolean) => {
        return isActive ? <span style={{ color: "green" }}>{t('CompanyTable.ACTIVE_1')}</span> : <span style={{ color: "red" }}>{t('CompanyTable.INACTIVE_1')}</span>
    }

    const renderCompanyType = (company: ICompany) => {
        if (company.companyType === CompanyType.PARENT)
            return t('CompanyTable.PARENT_COMPANY_1');
        if (company.parentCompany)
            return t('CompanyTable.SUBSIDIARY_1');
        return t('CompanyTable.COMPANY_1');
    }

    const columns: TableColumn<ICompany>[] = [
        {
            name: t('CompanyTable.COMPANY_2'),
            selector: row => row.name,
            cell: cell => <span data-test-id={cell.name + '-company'} data-tag={"allowRowEvents"}>{navigateOnName ?
                <Link to='' onClick={(e) => { handleChangeCompany(cell); e.preventDefault(); e.stopPropagation() }}>{cell.name}</Link> : cell.name}</span>,
            sortable: true,
        },
        {
            name: t('CompanyTable.LOCATION_1'),
            selector: row => row.city,
            sortable: true,
        },
        {
            name: t('CompanyTable.INDUSTRY_1'),
            cell: cell => renderHeadings(cell.headings),
            sortable: false,
        },
        {
            name: t('CompanyTable.ORG_NUMBER_1'),
            selector: row => row.orgNo || "",
            sortable: true,
        },
        {
            name: t('CompanyTable.PARENT_COMPANY_2'),
            selector: row => row.parentCompany?.name || "",
            cell: cell => (
                <span data-test-id={cell.parentCompany?.name + '-company'} data-tag={"allowRowEvents"}>{navigateOnName && cell.parentCompany ?
                    <Link to='' onClick={(e) => { handleChangeCompany(cell.parentCompany!); e.preventDefault(); e.stopPropagation() }}>{cell.parentCompany.name}</Link> : cell.parentCompany?.name}</span>
            ),
            sortable: true,
        },
        {
            name: t('CompanyTable.TYPE_1'),
            selector: row => renderCompanyType(row),
            sortable: true,
        },
        {
            name: t('CompanyTable.STATUS_1'),
            cell: row => renderStatus(row.isActive),
            selector: row => row.isActive,
            sortable: true,
        },
        {
            name: t('CompanyTable.FEATURE_SKILLS_1'),
            cell: row => renderStatus(row.featureSkills),
            selector: row => row.featureSkills,
            sortable: true,
        }
    ];

    showSettings && columns.push({
        name: '',
        right: true,
        cell: row => (
            <CustomDropdown toggleType={DropdownToggleType.GEAR}>
                <CustomDropdownItem
                    icon={Icon.EDIT}
                    text={t('CompanyTable.EDIT_1')}
                    onClick={() => {
                        setSelectedCompany(row);
                        setShowEditCompanyModal(true);
                    }}
                />
                <CustomDropdownItem
                    icon={Icon.IMAGE}
                    text={t('CompanyTable.MANAGE_LOGO_1')}
                    onClick={() => {
                        setSelectedCompany(row);
                        setShowEditLogoModal(true);
                    }}
                />
                <CustomDropdownItem
                    icon={row.isActive ? Icon.EYE_SLASH : Icon.EYE}
                    text={`${row.isActive ? t('CompanyTable.DISABLE_1') : t('CompanyTable.ACTIVATE_1')} ${t('CompanyTable.CUSTOMER_SUPPLIER')}`}
                    onClick={() => {
                        setSelectedCompany(row);
                        setShowInactiveCompanyModal(true);
                    }}
                />
            </CustomDropdown>
        )
    })

    const { isFetching: isFetchingCompanies, data: companies } = useGetCompanies(parentCompanyId!);

    const [filterText, setFilterText] = useState("");

    const filteredItems = companies?.data?.data.filter(
        item => (
            (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.city && item.city.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.orgNo && item.orgNo.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.parentCompany?.name && item.parentCompany?.name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.headings && item.headings.some(
                heading => heading.name.toLowerCase().includes(filterText.toLowerCase())
            ))
        ),
    );

    return <>
        <ContentContainer>
            <PaginatedDataTableClientSide<ICompany>
                data={filteredItems ?? []}
                progressPending={isFetchingCompanies}
                columns={columns}
                withFilter={searchable}
                filterText={filterText}
                setFilterText={(prev) => setFilterText(prev)}
                onRowClick={onRowClick}
                pointerOnHover={rowPointerOnHover}
            />
            <When condition={!!selectedCompany && showEditCompanyModal}>
                <EditCompanyModal
                    company={selectedCompany!}
                    hide={hide}
                    show={showEditCompanyModal} />
            </When>
            <When condition={!!selectedCompany && showEditLogoModal}>
                <CompanyEditLogoModal
                    company={selectedCompany!}
                    showEditLogoModal={showEditLogoModal}
                    setShowEditLogoModal={setShowEditLogoModal}
                />
            </When>
            <When condition={!!selectedCompany && showInactiveCompanyModal}>
                <SimpleConfirmModal
                    show={showInactiveCompanyModal}
                    header={<>{selectedCompany?.isActive ? t('CompanyTable.DISABLE_1') : t('CompanyTable.ACTIVATE_1')} {t('CompanyTable.CUSTOMER_SUPPLIER')}</>}
                    text={<>{t('CompanyTable.CONFIRM_1') + (selectedCompany?.isActive ? t('CompanyTable.DISABLE_2') : t('CompanyTable.ACTIVATE_2')) + t('CompanyTable.CONFIRM_2')}</>}
                    onClickOKText={t('CompanyTable.YES_1')}
                    onClickOK={handleToggleCompanyActive}
                    isLoadingClickOk={isTogglingCompanyActive}
                    onClickCancelText={t('CompanyTable.CANCEL_1')}
                    onClickCancel={() => {
                        setShowInactiveCompanyModal(false);
                        setSelectedCompany(null);
                    }}
                />
            </When>
        </ContentContainer>
    </>
}

export default CompanyTable;
